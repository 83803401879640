//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import wxHelper from './common/wxHelper'
import networking from "./common/networking";
import utils from './common/utils'
import api from './common/api'
import {LocalStorage} from "quasar";

export default {
  name: 'list',
  data() {
    return {
      matnrs: [],
      fullscreenLoading: false,
      searchText: '', //process.env.NODE_ENV == 'development' ? '41114' : '',
      notFound: false,
      products: []
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == 'home') {
      next(vm => {
        vm.searchText = '';
        vm.products = vm.$route.params.products;
      })
    } else {
      next(vm => {

      })
    }
  },
  methods: {
    handleScan: function () {
      wxHelper.scanQRCode((resultStr) => {
        console.log(resultStr);
        if (resultStr) {
          this.fullscreenLoading = true;
          networking.requestScanAPI(resultStr, (products) => {
            if (products.length == 1) {
              this.pushNextPage(products[0], 'Detail');
            } else {
              for (let item of products) {
                 item.image = api.imageHost + item.img;
              }
              this.products = products;
            }
          }, () => {
            this.fullscreenLoading = false;
          })
        }
      })
    },
    handleSearch: function () {
      console.log(this.searchText);
      let text = this.searchText.trim();
      // if (!utils.checkNumber(text)) {
      //   alert('请输入数字');
      //   return;
      // }
      if (text.length < 5 || text.length > 6) {
        alert('请输入5位或6位货号短码');
        return;
      }
      this.search(text.toUpperCase());
    },
    search: function (matnr) {
      // let formData = new FormData();
      // formData.append('matnr', matnr);
      this.fullscreenLoading = true;
      networking.requestSearchAPI(matnr.toUpperCase(), (products) => {
        console.log(products);
        this.matnrs = utils.getCahceMatnrs();
        if (products.length == 0) {
          alert('没有查询到相关数据');
        } else if (products.length == 1) {
          let item = products[0];
          item.image = api.imageHost + item.img;
          this.$router.push({name: 'detail', params: item});
        } else {
          for (let item of products) {
            item.image = api.imageHost + item.img;
          }
          this.products = products;
        }
      }, () => {
        this.fullscreenLoading = false;
      })
      /*
      var that = this;
      this.fullscreenLoading = true;
      this.axios.post(api.search, formData).then(function (response) {
        if (response.data.code == 9000) {
            let products = response.data.data;
            that.products = products;
            if (that.products.length == 1) {
              that.$router.push({name: 'detail', params: that.products[0]});
            }
        } else {
          that.products = [];
        }
        that.notFound = that.products.length == 0 ? true : false;
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        alert(error);
      })
      .then(function () {
        that.fullscreenLoading = false;
      });*/
    },
    productDetail: function (item) {
      console.log(item);
      this.pushNextPage(item, 'Detail');
      // this.$router.push({name: 'detail', params: item});
    },
    querySearch(queryString, cb) {
      let matnrs = this.matnrs;
      matnrs = matnrs.map(value => {
        return {value: value};
      })
      cb(matnrs);
    },
    pushNextPage(products, name) {
      if (name == 'List') {
        for (let item of products) {
          item.image = api.imageHost + item.img;
        }
      } else {
        products.image = api.imageHost + products.img;
      }
      LocalStorage.set('productParams', JSON.stringify(products));
      this.$router.push({path:`/shoppingGuideProducts/${name}`});
    },
  },
  mounted () {
    document.title = '产品列表';
    this.matnrs = utils.getCahceMatnrs();

    let productParams = LocalStorage.getItem('productParams');
    if (productParams) {
      let products = JSON.parse(productParams);
      this.products = products;
    }
    networking.requestJsSdkConfig()
    // this.products = this.$route.params.products;
  }
}
